import { useSessionContext } from '~/contexts/SessionContext';
import useAnonymousId from './useAnonymousId';

const useTracking = () => {
  const anonId = useAnonymousId();
  const { pageSessionId } = useSessionContext();

  return {
    anonId,
    trackingNotes: `segment_anonid:${anonId}|page_session_id:${pageSessionId}`,
  };
};

export default useTracking;
