import { Box } from '@chakra-ui/layout';
import {
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { IModalProps } from './Modal.types';

const Modal: React.FC<IModalProps> = ({
  visible = false,
  allowInnerScroll = false,
  onClose,
  children,
  title = '',
  size,
}) => {
  return (
    <ChakraModal
      onClose={onClose}
      size={size}
      isOpen={visible}
      preserveScrollBarGap
      returnFocusOnClose
    >
      <ModalOverlay />
      <ModalContent>
        <Box overflow="hidden" borderRadius="md">
          <ModalCloseButton data-testid="modal-close-btn" />
          <Box
            data-testid="modal-content"
            p={8}
            overflowY={allowInnerScroll ? 'scroll' : undefined}
            maxH={allowInnerScroll ? '80vh' : undefined}
            className={allowInnerScroll ? 'custom-scrollbar' : undefined}
          >
            <ModalHeader textAlign="center">{title}</ModalHeader>
            {children}
          </Box>
        </Box>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
