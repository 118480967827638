import { ParsedUrlQuery } from 'querystring';
import { isAState } from './isAState';

export const getStateFromURL = (
  params: ParsedUrlQuery | null
): string | null => {
  if (!params) return null;

  const careTypeOrState = params?.careTypeOrState;
  const stateOrCountyOrCity = params?.stateOrCountyOrCity;
  const state = params?.state;

  if (isAState(careTypeOrState as string)) return careTypeOrState as string;
  if (isAState(stateOrCountyOrCity as string))
    return stateOrCountyOrCity as string;
  if (isAState(state as string)) return state as string;

  return null;
};
