import { slugToLabel } from '@utils/breadcrumb';
import { getCountyFromURL } from './getCountyFromURL';
import { getStateFromURL } from './getStateFromURL';

interface GetStateAndCountyParams {
  careTypeOrState?: string;
  stateOrCountyOrCity?: string;
  countyOrCityOrProvider?: string;
  careTypeOrCountyOrCity?: string;
}

export const getStateAndCountyFromPageContext = (params) => {
  const {
    careTypeOrState = '',
    stateOrCountyOrCity = '',
    countyOrCityOrProvider = '',
    careTypeOrCountyOrCity = '',
  }: GetStateAndCountyParams = params;

  const state = getStateFromURL({ careTypeOrState, stateOrCountyOrCity }) ?? '';
  const county = getCountyFromURL({
    stateOrCountyOrCity,
    countyOrCityOrProvider,
    careTypeOrCountyOrCity,
  });

  return {
    state: slugToLabel(state),
    county: slugToLabel(county.replace('-county', '')),
  };
};
