import { Box, Heading, VStack } from '@chakra-ui/layout';

import {
  stepContent,
  stepSubmission,
} from '@components/Analytics/events/eventContracts';
import useFormStepSubmission, {
  FormType,
} from '@components/Analytics/events/FormStepSubmission';
import useFormSubmission from '@components/Analytics/events/FormSubmission';
import useInquirySubmission from '@components/Analytics/events/InquirySubmission';
import { PageContext } from '@components/LayoutStructure/EditablePage';
import trackAnalyticsEvent from '@utils/analytics';
import { getCareTypeFromURL } from '@utils/getCareTypeFromURL';
import { getCityFromURL } from '@utils/getCityFromURL';
import { getColor } from '@utils/getColor';
import { getStateAndCountyFromPageContext } from '@utils/getStateAndCountyFromPage';
import { getStateFromURL } from '@utils/getStateFromURL';
import snakeCase from 'lodash/snakeCase';
import dynamic from 'next/dynamic';
import { ParsedUrlQuery } from 'querystring';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import CatalogContext from '~/contexts/CatalogContext';
import ProviderContext from '~/contexts/Provider';
import { Metadata } from '~/types/Magnolia';
import { RollUpType } from '~/types/RollUpType';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

import { CTAAction, Display } from './InquiryForm.types';
import {
  formFieldPrompts,
  formFieldTypes,
  inquiryFormSchema,
  sendInquiryForm,
} from './InquiryForm.utils';
import InquiryFormFields from './InquiryFormFields';
import InquiryHorizontalForm from './InquiryHorizontalForm';
import InquiryModalForm from './InquiryModalForm';
import InquiryVerticalForm from './InquiryVerticalForm';

import {
  getSavedValues,
  saveInquiryForm,
} from '@components/InquiryForm/saveUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import useCookieStorageValue from '@hooks/use-cookie-storage-value';
import { getRollupForCareType } from '@utils/careTypeRollups';
import { getRollupFromURL } from '@utils/getRollupFromURL';
import { getUTMParameters } from '@utils/utmParameters';
import { useRouter } from 'next/router';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import useTracking from '~/hooks/useTracking';
import SiteContext from '~/contexts/SiteContext';
import {
  DeviceVisibility,
  useResponsiveDisplay,
} from '@hooks/useResponsiveDisplay';
import InquiryProviderContactModal from './InquiryProviderContactModal';
import { LocationResponse } from '@services/modular-monolith/types/location.type';
import { InquiryFormFieldsType } from '~/types/Inquiry';
import { useModal } from '~/contexts/ModalContext';

export type InquiryFormProps = {
  formId: string;
  templateId?: string;
  title?: string;
  display: Display;
  deviceVisibility?: DeviceVisibility;
  ctaText?: string;
  overrideDefaultType?: boolean;
  ctaColorScheme?: string;
  bgBorderRadius?: string;
  ctaAction: CTAAction;
  rollUpType?: RollUpType;
  legalDisclosure?: string;
  defaultCareType?: string;
  defaultCareLocation?: string;
  defaultThankYouPage?: string;
  thankYouMessage?: string;
  thankYouMessageColor?: string;
  isSideBar?: boolean;
  hasWrapContainer?: boolean;
  bgColor?: string;
  bgColorRange?: string;
  textColor?: string;
  textColorRange?: string;
  linkColor?: string;
  linkColorRange?: string;
  metadata?: Pick<Metadata, '@id'>;
  height?: string;
  marginBottom?: string;
  showPayingWithMedicaid?: boolean;
  relationshipLabel?: string;
  nameLabel?: string;
  phoneLabel?: string;
  emailLabel?: string;
  values?: {
    fullName?: string;
    email?: string;
    phoneNumber?: string;
    payingWithMedicaid?: boolean;
    whoAreYouLookingFor?: string;
  };
  errorMessage?: string;
  noValidate?: boolean;
};

const CARING_THANK_YOU_PAGE_URL =
  process.env.NEXT_PUBLIC_CARING_THANK_YOU_PAGE_URL;

const InquiryFormComponent = ({
  formId,
  templateId,
  title = 'Get costs',
  display,
  deviceVisibility,
  ctaText = 'Get Costs',
  ctaColorScheme = 'accent',
  overrideDefaultType = false,
  ctaAction,
  rollUpType,
  legalDisclosure,
  defaultCareType = 'assisted-living',
  defaultCareLocation,
  defaultThankYouPage = '',
  thankYouMessage = '',
  thankYouMessageColor = '',
  isSideBar,
  hasWrapContainer = false,
  bgBorderRadius = '0',
  bgColor,
  bgColorRange,
  textColor,
  textColorRange,
  linkColor,
  linkColorRange,
  metadata,
  height,
  marginBottom,
  showPayingWithMedicaid = true,
  relationshipLabel,
  nameLabel,
  phoneLabel,
  emailLabel,
  values = {
    fullName: '',
    email: '',
    phoneNumber: '',
    payingWithMedicaid: false,
    whoAreYouLookingFor: '',
  },
  errorMessage = 'Submission error. Please call (800) 558-0653 to speak with a Caring Expert.',
  noValidate = false,
}: InquiryFormProps): JSX.Element => {
  const router = useRouter();
  const { visible: visibleFormModal, hide: hideFormModal } = useModal(formId);
  // For enhanced search list inquiry form
  const providerValue = useCookieStorageValue('provider');
  const selectedProvider =
    typeof providerValue === 'string' ? JSON.parse(providerValue) : {};

  const id = useId();
  const [formError, setFormError] = useState('');

  const [sent, setSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalProviderDetails, setModalProviderDetails] = useState<
    LocationResponse | undefined
  >();

  const pageProps = useContext(PageContext);
  const catalogContext = useContext(CatalogContext);
  const providerContext = useContext(ProviderContext)?.provider;
  const inquirySubmission = useInquirySubmission();

  const formStepSubmission = useFormStepSubmission();
  const formSubmission = useFormSubmission();
  const { anonId, trackingNotes } = useTracking();
  const isModal =
    display === Display.FULL_PAGE_MODAL ||
    display === Display.FIT_CONTENT_MODAL;
  const isEmbedded = !isSideBar && !isModal;
  const params = pageProps?.context.params as ParsedUrlQuery;

  const state = getStateFromURL(params);

  const { county } = getStateAndCountyFromPageContext(params ?? '');
  const city = county ? catalogContext?.city || '' : getCityFromURL(params);

  const careType = overrideDefaultType
    ? getCareTypeFromURL(params) || defaultCareType
    : defaultCareType;
  const calculatedRollUpType =
    rollUpType || getRollupFromURL(params) || getRollupForCareType(careType);

  const providerSlug = providerContext ? router.asPath : selectedProvider.slug;

  const cityState = city && state ? `${city}, ${state}` : 'UNKNOWN';
  const careLocation = defaultCareLocation ?? cityState;

  const formElementID = `${id}-inquiry-form`;

  const formFieldsNamespace = [
    formId,
    title,
    display,
    ctaText,
    ctaColorScheme,
    ctaAction,
    calculatedRollUpType,
    legalDisclosure ?? '',
    thankYouMessage,
    bgBorderRadius,
    bgColor ?? '',
    bgColorRange ?? '',
    textColor ?? '',
    textColorRange ?? '',
  ].join(' ');

  const metaDataId = metadata?.['@id'] || templateId || id;
  const formTemplateId = uuidv5(formFieldsNamespace, metaDataId);

  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path ?? '';

  const savedValues = getSavedValues();

  const typeOfCare = careType ? snakeCase(careType) : 'assisted_living';
  const providerId =
    providerContext?.services?.[0]?.legacyResourceId ||
    selectedProvider?.services?.[0]?.legacyResourceId ||
    providerContext?.id ||
    selectedProvider?.id;
  const formInstanceId = useMemo(() => uuidv4(), []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
    getValues,
  } = useForm<InquiryFormFieldsType>({
    mode: 'onBlur',
    resolver: zodResolver(inquiryFormSchema),
    defaultValues: {
      fullName: savedValues.fullName || '',
      email: savedValues.email || '',
      phoneNumber: savedValues.phoneNumber || '',
      payingWithMedicaid: false,
      whoAreYouLookingFor: savedValues.whoAreYouLookingFor || '',
      typeOfCare,
      anonId,
      formId: formInstanceId,
      trackingNotes,
      providerId,
      careLocation,
      source: domain,
    },
  });

  useEffect(() => {
    setValue(
      'whoAreYouLookingFor',
      values.whoAreYouLookingFor || savedValues.whoAreYouLookingFor || ''
    );
    setValue('fullName', values.fullName || savedValues.fullName || '');
    setValue('email', values.email || savedValues.email || '');
    setValue(
      'phoneNumber',
      values.phoneNumber || savedValues.phoneNumber || ''
    );
    setValue('payingWithMedicaid', values.payingWithMedicaid || false);
  }, [values, setValue, savedValues]);

  const formName = `${pageProps?.page['mgnl:template']?.replace(
    'spa-lm:pages/',
    ''
  )}-${calculatedRollUpType}-${display}-${ctaAction}`
    .toLowerCase()
    .replace(' ', '-');

  const handleFormEvents = useCallback(
    (data, formInstanceId: string) => {
      const formStepInstanceId = uuidv4();
      const stepContent: stepContent[] = [];
      const stepSubmission: stepSubmission[] = [];
      const stepIndex = 1;
      const stepId = uuidv5(String(stepIndex), metaDataId);
      if (Object.keys(data).length > 0) {
        Object.entries(data).map((prompt, i) => {
          const field = prompt[0];
          const value = prompt[1] as string;

          stepContent.push({
            prompt_id: uuidv5(field, metaDataId),
            prompt_type: formFieldTypes[field] ?? '',
            prompt_instance_id: uuidv4(),
            prompt_index: i + 1,
            prompt_value: formFieldPrompts[field] ?? '',
            response_array: [
              {
                response_value: value,
                response_id: uuidv4(),
              },
            ],
          });
        });
        stepSubmission.push({
          step_id: stepId,
          step_instance_id: formStepInstanceId,
          step_index: stepIndex,
        });
      }

      formStepSubmission({
        form_template_id: formTemplateId,
        form_instance_id: formInstanceId,
        form_type: FormType.INQUIRY,
        step_id: stepId,
        step_instance_id: formStepInstanceId,
        step_index: 1,
        step_content: stepContent,
      });
      formSubmission({
        form_template_id: formTemplateId,
        form_instance_id: formInstanceId,
        step_submissions: stepSubmission,
        form_type: FormType.INQUIRY,
      });
    },
    [metaDataId, formTemplateId, formStepSubmission, formSubmission]
  );

  const onSubmit = handleSubmit(async (data) => {
    // Disable the submit button to prevent multiple submissions
    setIsSubmitting(true);
    trackAnalyticsEvent('form-submission');
    const trackedValues = {
      ...data,
      utmParams: getUTMParameters(),
    };

    try {
      const formInstanceId = uuidv4();
      const responseData = await sendInquiryForm({
        ...trackedValues,
        formId: formInstanceId,
      });

      handleFormEvents(
        { ...data, response: responseData.message },
        formInstanceId
      );
      if (responseData.success) {
        inquirySubmission({
          ...trackedValues,
          name: formName,
          display: display,
          type: ctaAction,
        });
        reset();

        //TODO: Set the cookie here
        if (thankYouMessage) {
          setSent(true);
        } else {
          if (responseData.provider) {
            setModalProviderDetails(responseData.provider);
            isModal && hideFormModal();
          } else {
            // INFO this fallback is used on SEM pages -- we always redirect the care seeker to the Thank-You page.
            window.location.href =
              defaultThankYouPage ||
              (providerSlug
                ? `${CARING_THANK_YOU_PAGE_URL}/?slug=${providerSlug}&phone=${data.phoneNumber}`
                : `${CARING_THANK_YOU_PAGE_URL}/?phone=${data.phoneNumber}`);
          }
        }
      } else {
        setFormError(errorMessage);
        console.error(responseData.message);
      }
    } catch (error: unknown) {
      setFormError(errorMessage);
      console.error(error);
    }

    // Re-enable the submit button
    setIsSubmitting(false);
  });

  let fields = (
    <InquiryFormFields
      formId={formElementID}
      register={register}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      errors={errors as FieldErrorsImpl<Record<string, any>>}
      formError={formError}
      ctaText={ctaText}
      rollUpType={calculatedRollUpType}
      textColor={getColor(textColor, textColorRange)}
      ctaColorScheme={ctaColorScheme}
      isSubmitting={isSubmitting}
      method="post"
      action="/api/inquiry"
      onSubmit={onSubmit}
      autoFocus={isModal}
      onChange={() => saveInquiryForm(getValues())}
      showPayingWithMedicaid={showPayingWithMedicaid}
      relationshipLabel={relationshipLabel}
      nameLabel={nameLabel}
      phoneLabel={phoneLabel}
      emailLabel={emailLabel}
      formTemplateId={formTemplateId}
      typeOfCare={typeOfCare}
      anonId={anonId}
      formInstanceId={formInstanceId}
      trackingNotes={trackingNotes}
      providerId={providerId}
      source={domain}
      careLocation={careLocation}
      noValidate={noValidate}
    />
  );

  if (sent && thankYouMessage) {
    fields = (
      <Box display="flex" minH={60} w="full">
        <Box
          my="auto"
          w="full"
          textAlign="center"
          textColor={
            thankYouMessageColor
              ? `${thankYouMessageColor}.600`
              : getColor(textColor, textColorRange)
          }
          dangerouslySetInnerHTML={{ __html: thankYouMessage }}
        />
      </Box>
    );
  }

  const isHidden = useResponsiveDisplay(deviceVisibility);
  if (isHidden) {
    return <></>;
  }

  if (!display && !ctaAction) return <></>;
  const backgroundColor = bgColor
    ? getColor(bgColor, bgColorRange)
    : 'transparent';
  const spacing = isEmbedded ? 8 : 0;
  const alignItems = isEmbedded ? 'center' : 'start';
  const maxWidth = isEmbedded ? 'xl' : '100%';
  const boxLightStyle = isEmbedded
    ? ''
    : { bgColor: 'white', p: '6', boxShadow: 'xs', borderRadius: 'sm' };

  const ContainerComponent = hasWrapContainer ? Container : Fragment;
  const disclaimerLinkColor = linkColor
    ? getColor(linkColor, linkColorRange)
    : '';
  return (
    <ContainerComponent>
      <Box
        backgroundColor={backgroundColor}
        p={spacing}
        mb={marginBottom ?? spacing}
        borderRadius={bgBorderRadius}
        height={height}
      >
        {!isModal && (
          <VStack alignItems={alignItems} spacing="1">
            <Box width="100%" maxW={maxWidth}>
              {title && (
                <Heading
                  size={isEmbedded ? 'md' : 'sm'}
                  pb={2}
                  px={{ base: 6, md: 0 }}
                  color={getColor(textColor, textColorRange)}
                >
                  {title}
                </Heading>
              )}
              <Box width="full" {...boxLightStyle}>
                {display === Display.VERTICAL && (
                  <InquiryVerticalForm>{fields}</InquiryVerticalForm>
                )}
                {display === Display.HORIZONTAL && (
                  <InquiryHorizontalForm>{fields}</InquiryHorizontalForm>
                )}
                {legalDisclosure && !sent && (
                  <Box
                    pt="4"
                    fontSize={isEmbedded ? 'xs' : 'sm'}
                    className="magnolia-text"
                    sx={{
                      p: { color: getColor(textColor, textColorRange) },
                      a: { color: disclaimerLinkColor },
                    }}
                    dangerouslySetInnerHTML={{ __html: legalDisclosure }}
                  />
                )}
              </Box>
            </Box>
          </VStack>
        )}
        {isModal && (
          <InquiryModalForm
            title={title}
            size={display}
            allowInnerScroll
            visible={visibleFormModal}
            onClose={hideFormModal}
          >
            <>
              {fields}
              {legalDisclosure && !sent && (
                <Box
                  color={getColor(textColor, textColorRange)}
                  pt="4"
                  fontSize="sm"
                  className="magnolia-text"
                  sx={{
                    p: { color: getColor(textColor, textColorRange) },
                    a: { color: disclaimerLinkColor },
                  }}
                  dangerouslySetInnerHTML={{ __html: legalDisclosure }}
                />
              )}
            </>
          </InquiryModalForm>
        )}
        {modalProviderDetails ? (
          <InquiryProviderContactModal
            onClose={() => setModalProviderDetails(undefined)}
            provider={modalProviderDetails}
            phone={values.phoneNumber || savedValues.phoneNumber || ''}
            redirectURL={providerSlug}
          />
        ) : null}
      </Box>
    </ContainerComponent>
  );
};

const InquiryForm: React.FC<InquiryFormProps> = withHydrationOnDemand({
  on: ['idle', 'visible'],
  initialVisible: true,
})(InquiryFormComponent);

export default InquiryForm;
