import { Box, Heading, VStack, Text, Divider } from '@chakra-ui/react';
import { FC } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { formatNumberLocale } from '@utils/number';
import { isCallCenterOpen } from '~/utils';
import {
  getUserDetailedTimeZone,
  relativeTimeToCallcenterTime,
} from '@utils/dates';

const BUSINESS_HOURS = {
  weekdaysFrom: '05:00',
  weekdaysTo: '21:00',
  weekendFrom: '07:00',
  weekendTo: '21:00',
};

const PhoneIcon = () => (
  <svg
    fill="none"
    height="80"
    viewBox="0 0 80 80"
    width="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="38.846" stroke="#2A948B" strokeWidth="2.3077" />
    <path
      d="m54.677 48.067c-2.4518 0-4.8239-0.3987-7.0365-1.1163-0.3466-0.1175-0.7194-0.135-1.0755-0.0503-0.356 0.0846-0.6811 0.2678-0.9378 0.5287l-3.1296 3.9269c-5.6412-2.691-10.924-7.7741-13.734-13.615l3.887-3.309c0.5382-0.5582 0.6977-1.3356 0.4784-2.0332-0.7375-2.2127-1.1162-4.5848-1.1162-7.0366 0-1.0764-0.8971-1.9734-1.9735-1.9734h-6.897c-1.0764 0-2.3721 0.4784-2.3721 1.9734 0 18.518 15.409 33.907 33.907 33.907 1.4153 0 1.9735-1.2558 1.9735-2.3521v-6.8771c0-1.0764-0.897-1.9734-1.9735-1.9734z"
      fill="#2A948B"
    />
    <path
      d="m58.398 39.046h-4.0614c0-7.8588-6.3561-14.215-14.215-14.215v-4.0614c4.8471 0 9.4957 1.9255 12.923 5.353 3.4274 3.4274 5.353 8.076 5.353 12.923z"
      fill="#2A948B"
    />
    <path
      d="m50.275 39.046h-4.0614c0-3.371-2.7211-6.0921-6.092-6.0921v-4.0613c5.6047 0 10.153 4.5487 10.153 10.153z"
      fill="#2A948B"
    />
  </svg>
);

interface PricingProps {
  title: string;
  price: string;
  comment: string;
}

const Pricing: FC<PricingProps> = ({ title, price, comment }) => (
  <Box py="3">
    <Heading as="h3" size="sm">
      {title}
    </Heading>
    <Text fontSize="xl" fontWeight="bold" pt="3" lineHeight="shorter">
      {price}
    </Text>
    <Text lineHeight="shorter" fontSize="sm">
      {comment}
    </Text>
  </Box>
);

interface ModalProviderService {
  id: string;
  category?: {
    name: string;
  };
  costs?: {
    startingPriceCents: number;
  };
}

export interface ModalProvider {
  id: string;
  name: string;
  city?: { name: string };
  state?: { name: string };
  services?: ModalProviderService[];
}

interface InquiryProviderContactModalProps {
  phone: string;
  onClose: () => void;
  provider: ModalProvider;
  redirectURL?: string;
}

const InquiryProviderContactModal: FC<InquiryProviderContactModalProps> = ({
  phone,
  onClose,
  provider,
  redirectURL,
}) => {
  const isNowWithinCallCenterHours = isCallCenterOpen(
    BUSINESS_HOURS.weekdaysFrom,
    BUSINESS_HOURS.weekdaysTo,
    BUSINESS_HOURS.weekendFrom,
    BUSINESS_HOURS.weekendTo
  );
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const services =
    provider.services
      ?.filter(
        (service): service is Required<ModalProviderService> =>
          !!(service?.costs?.startingPriceCents && service?.category?.name)
      )
      .sort((a, b) => a.category.name.localeCompare(b.category.name)) || [];

  const handleClose = () => {
    if (redirectURL) {
      window.location.href =
        redirectURL +
        (services.length > 0
          ? `#provider-costs-${provider.id}`
          : `#provider-comparison-${provider.id}`);
    }

    onClose();
  };

  return (
    <Modal isOpen onClose={handleClose} size={{ base: 'sm', md: 'md' }}>
      <ModalOverlay />
      <ModalContent p="2" rounded="12">
        <ModalCloseButton mt="5" mr="5" />
        <ModalHeader pt="16">
          <Heading as="h3" fontSize="2xl" lineHeight="short">
            Costs for {provider.name}
            {provider.city?.name || provider.state?.name ? ' in ' : ''}
            {provider.city?.name && provider.state?.name
              ? `${provider.city.name}, ${provider.state.name}`
              : provider.city?.name || provider.state?.name}
          </Heading>
        </ModalHeader>
        <ModalBody py="0">
          <Divider />
          <VStack divider={<Divider />} align="stretch" spacing={0}>
            {services.length === 0 && (
              <Text fontSize="xl" py="3">
                Pricing unavailable from this provider
              </Text>
            )}
            {services.map((service) => (
              <Pricing
                key={service.id}
                comment="Starting Price"
                price={`$${formatNumberLocale(
                  service.costs.startingPriceCents / 100
                )}/mo`}
                title={service.category.name}
              />
            ))}
          </VStack>
          <Divider />
          <VStack align="center" spacing="1" py="6">
            <Box py="3">
              <PhoneIcon />
            </Box>
            {isNowWithinCallCenterHours ? (
              <VStack
                align="center"
                spacing="1"
                lineHeight="short"
                fontSize="xl"
              >
                <Text>A Caring Family Advisor will call</Text>
                <Text fontWeight="bold">{phone}</Text>
                <Text>
                  {services.length === 0
                    ? 'in the next 2 minutes'
                    : 'within 2 minutes'}
                </Text>
                {services.length === 0 && <Text>to help with your search</Text>}
              </VStack>
            ) : (
              <VStack
                spacing="1"
                lineHeight="short"
                fontSize="md"
                textAlign="center"
              >
                <Text>Get in touch with us during business hours:</Text>
                <Text>
                  {[
                    'Monday - Friday,',
                    relativeTimeToCallcenterTime(
                      BUSINESS_HOURS.weekdaysFrom,
                      timezone
                    ),
                    '-',
                    relativeTimeToCallcenterTime(
                      BUSINESS_HOURS.weekdaysTo,
                      timezone
                    ),
                    getUserDetailedTimeZone(),
                  ].join(' ')}
                </Text>
                <Text>
                  {[
                    'Saturday and Sunday,',
                    relativeTimeToCallcenterTime(
                      BUSINESS_HOURS.weekendFrom,
                      timezone
                    ),
                    '-',
                    relativeTimeToCallcenterTime(
                      BUSINESS_HOURS.weekendTo,
                      timezone
                    ),
                    getUserDetailedTimeZone(),
                  ].join(' ')}
                </Text>
              </VStack>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InquiryProviderContactModal;
