import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { GridItem, Text, VStack } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import useFormFieldFocused from '@components/Analytics/events/FormFieldFocused';
import { FormType } from '@components/Analytics/events/FormStepSubmission';
import { useEffect, useRef, useState } from 'react';
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { InquiryFormFieldsType } from '~/types/Inquiry';
import { RollUpType } from '~/types/RollUpType';
import useReactInputMask from '~/hooks/use-react-input-mask';
import {
  formFieldPrompts,
  handleFormBlur,
  handleFormFocus,
  inquiryForOptions,
} from './InquiryForm.utils';

type Props = {
  formId: string;
  register: UseFormRegister<InquiryFormFieldsType>;
  setValue: UseFormSetValue<InquiryFormFieldsType>;
  getValues: UseFormGetValues<InquiryFormFieldsType>;
  trigger: UseFormTrigger<InquiryFormFieldsType>;
  errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
  ctaText: string;
  rollUpType: RollUpType;
  textColor: string;
  ctaColorScheme: string;
  formError?: string;
  isSubmitting?: boolean;
  showPayingWithMedicaid: boolean;
  autoFocus?: boolean;
  relationshipLabel?: string;
  nameLabel?: string;
  phoneLabel?: string;
  emailLabel?: string;
  onSubmit: () => void;
  onChange: () => void;
  formTemplateId: string;
  method?: string;
  action?: string;
  typeOfCare: string;
  anonId: string;
  formInstanceId: string;
  trackingNotes: string;
  providerId?: string;
  source: string;
  careLocation: string;
  noValidate?: boolean;
};

const InquiryFormFields: React.FC<Props> = ({
  formId,
  ctaText,
  rollUpType,
  register,
  setValue,
  trigger,
  errors,
  textColor,
  ctaColorScheme,
  formError,
  autoFocus = false,
  isSubmitting = false,
  showPayingWithMedicaid,
  relationshipLabel = formFieldPrompts['whoAreYouLookingFor'],
  nameLabel = formFieldPrompts['fullName'],
  phoneLabel = formFieldPrompts['phoneNumber'],
  emailLabel = formFieldPrompts['email'],
  onSubmit,
  onChange,
  getValues,
  formTemplateId,
  action,
  method,
  typeOfCare,
  anonId,
  formInstanceId,
  trackingNotes,
  providerId,
  source,
  careLocation,
  noValidate = false,
}) => {
  const [formFocused, setFormFocused] = useState(false);
  const formFieldFocused = useFormFieldFocused();
  const defaultInputTextColor = 'graphic.700';
  const defaultInputBackgroundColor = 'white';
  const inquiryForm = useRef<HTMLFormElement>(null);

  const phoneNumberMaskProps = {
    mask: '(999) 999-9999',
    maskChar: '_',
    isUSPhoneNumber: true,
  };
  const { beforeMaskedValueChange, formatValueOnAutofill } =
    useReactInputMask(phoneNumberMaskProps);

  useEffect(() => {
    // defaultValue doesn't work properly with react-input-mask, so we need to set the value manually
    const value = getValues('phoneNumber');
    if (value) {
      setValue('phoneNumber', value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [setValue, getValues]);

  return (
    <form
      action={action}
      method={method}
      style={{ width: '100%' }}
      id={formId}
      onSubmit={onSubmit}
      data-testid="inquiry-form"
      onChange={onChange}
      onClick={(e) =>
        handleFormFocus(
          e,
          formFocused,
          setFormFocused,
          formFieldFocused,
          formTemplateId,
          FormType.INQUIRY
        )
      }
      onBlur={(e) =>
        handleFormBlur(e, formFocused, inquiryForm, setFormFocused)
      }
      ref={inquiryForm}
      noValidate={noValidate}
    >
      {/* Who are you looking for? */}
      <FormControl
        fontSize="md"
        isInvalid={Boolean(errors.whoAreYouLookingFor)}
      >
        <Text my={2} color={textColor}>
          {relationshipLabel}
        </Text>
        <Select
          autoFocus={autoFocus} // It is necessary for the Enter key submission
          {...register('whoAreYouLookingFor', { required: true })}
          backgroundColor={defaultInputBackgroundColor}
          color={defaultInputTextColor}
          required
        >
          <option value=""></option>
          {inquiryForOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {String(errors?.whoAreYouLookingFor?.message)}
        </FormErrorMessage>
      </FormControl>

      {/* Full Name */}
      <FormControl color={textColor} isInvalid={Boolean(errors.fullName)}>
        <Text my={2} color={textColor}>
          {nameLabel}
        </Text>
        <Input
          placeholder=""
          {...register('fullName', {
            required: true,
            // Remove error message if current input is already valid
            onChange: () => {
              if (Boolean(errors.fullName)) {
                trigger('fullName');
              }
            },
          })}
          backgroundColor={defaultInputBackgroundColor}
          color={defaultInputTextColor}
          required
        />
        <FormErrorMessage>{String(errors?.fullName?.message)}</FormErrorMessage>
      </FormControl>

      {/* Phone number */}
      <FormControl color={textColor} isInvalid={Boolean(errors?.phoneNumber)}>
        <Text my={2} color={textColor}>
          {phoneLabel}
        </Text>
        <Input
          as={InputMask}
          type="tel"
          mask={phoneNumberMaskProps.mask}
          maskChar={phoneNumberMaskProps.maskChar}
          beforeMaskedValueChange={beforeMaskedValueChange}
          {...register('phoneNumber', {
            required: true,
            // Bugfix: on Chrome in iOS, autofill phone numbers are removed when value is not already
            // formatted as the mask. This happens because we're using InputMask here, and this is a
            // know issue of react-input-mask. See
            // https://www.npmjs.com/package/react-input-mask#autofill for more details.
            // And because this does not happen on desktop browsers, this cannot be tested on our specs,
            // so be sure to manually test this on a Chrome in iOS when changing this event code
            onChange: (e) => {
              const { changed, value } = formatValueOnAutofill(e);
              if (changed) {
                setValue('phoneNumber', value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              } else if (Boolean(errors.phoneNumber)) {
                trigger('phoneNumber');
              }
            },
          })}
          placeholder=""
          backgroundColor={defaultInputBackgroundColor}
          color={defaultInputTextColor}
          required
          pattern="^(\+?1[\-.\s]?)?\(?([0-9]{3})\)?\s?[\-.\s]?\s?([0-9]{3})[\-.\s]?([0-9]{4})$"
        />
        <FormErrorMessage>
          {String(errors?.phoneNumber?.message)}
        </FormErrorMessage>
      </FormControl>

      {/* E-mail */}
      <FormControl color={textColor} isInvalid={Boolean(errors.email)}>
        <Text my={2} color={textColor}>
          {emailLabel}
        </Text>
        <Input
          {...register('email', {
            required: true,
            // Remove error message if current input is already valid
            onChange: () => {
              if (Boolean(errors.email)) {
                trigger('email');
              }
            },
          })}
          backgroundColor={defaultInputBackgroundColor}
          color={defaultInputTextColor}
          required
          type="email"
        />
        <FormErrorMessage>{String(errors?.email?.message)}</FormErrorMessage>
      </FormControl>

      {rollUpType === RollUpType.SENIOR_LIVING && showPayingWithMedicaid && (
        <VStack py="2" alignItems="start">
          {/* Paying with Medicaid only */}
          <Checkbox color={textColor} {...register('payingWithMedicaid')}>
            {formFieldPrompts['payingWithMedicaid']}
          </Checkbox>
        </VStack>
      )}

      {/* Submit Button  */}
      <GridItem
        gridColumn="span var(--columns, 1)"
        mt={showPayingWithMedicaid ? 0 : 2}
      >
        <VStack>
          <Button
            form={formId} // Required for form submission to work if the button is inside a portal (e.g. a modal). See https://github.com/facebook/react/issues/22470 for more info.
            isLoading={isSubmitting}
            colorScheme={ctaColorScheme}
            width="100%"
            type="submit"
            px={10}
            mt={2}
          >
            {ctaText}
          </Button>
          {formError && <Text color="error.500">{formError}</Text>}
        </VStack>
      </GridItem>
      <Input {...register('typeOfCare')} type="hidden" value={typeOfCare} />
      <Input {...register('anonId')} type="hidden" value={anonId} />
      <Input {...register('formId')} type="hidden" value={formInstanceId} />
      <Input
        {...register('trackingNotes')}
        type="hidden"
        value={trackingNotes}
      />
      <Input {...register('providerId')} type="hidden" value={providerId} />
      <Input {...register('source')} type="hidden" value={source} />
      <Input {...register('careLocation')} type="hidden" value={careLocation} />
    </form>
  );
};

export default InquiryFormFields;
