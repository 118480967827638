import { ParsedUrlQuery } from 'querystring';
import { isARollUpType } from './isARollUpType';

export const getRollupFromURL = (
  params: ParsedUrlQuery | null
): string | null => {
  const { rollUpType } = params || {};
  if (isARollUpType(rollUpType)) return rollUpType;
  return null;
};
