import isACountyOrParish from './isACountyOrParish';

interface GetCountyParams {
  stateOrCountyOrCity?: string;
  countyOrCityOrProvider?: string;
  careTypeOrCountyOrCity?: string;
}

export const getCountyFromURL = (params: GetCountyParams): string => {
  const {
    countyOrCityOrProvider = '',
    stateOrCountyOrCity = '',
    careTypeOrCountyOrCity = '',
  } = params;

  if (isACountyOrParish(countyOrCityOrProvider)) return countyOrCityOrProvider;
  if (isACountyOrParish(stateOrCountyOrCity)) return stateOrCountyOrCity;
  if (isACountyOrParish(careTypeOrCountyOrCity)) return careTypeOrCountyOrCity;

  return '';
};
