import { BreadcrumbItem } from '@components/Navigation/Breadcrumb';

export const slugToLabel = (slug: string) => {
  if (!slug) return '';
  return slug
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
};

export const getLinksFromPath = (path: string): Array<BreadcrumbItem> => {
  const paths = path
    .split('?')[0] // remove query params
    .split('#')[0] // remove anchor
    .split('/')
    .filter((p) => p);
  const links = paths.map((p, i) => {
    const url = `/${paths.slice(0, i + 1).join('/')}`;
    return {
      url,
      label: slugToLabel(p),
    };
  });
  return links;
};
