import { formValue, inquirySubmission } from './eventContracts';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import segmentEvents, { getCookieNameFromEvent } from '~/config/segment-events';
import CookieStorage from 'utils/cookieStorage';
import { ONE_DAY_IN_MILLISECONDS } from '~/constants';
import SiteContext, { SiteContextType } from '~/contexts/SiteContext';
import { useContext } from 'react';

interface FormData {
  [key: string]: any;
}

interface InquirySubmissionType {
  sessionContext: SessionContextType;
  fields: InquirySubmissionEvent & FormData;
  siteContext: SiteContextType;
}

interface InquirySubmissionEvent {
  name: string;
  display: string;
  type: string;
}

export const flattenObject = (obj: { [key: string]: any }) => {
  let flattened = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      flattened = { ...flattened, ...flattenObject(value) };
    } else {
      flattened[key] = value;
    }
  });
  return flattened;
};

export const getFormValues = (formData: FormData): formValue[] => {
  const flattenData = flattenObject(formData);
  return Object.keys(flattenData).map((key) => ({
    name: key,
    value: flattenData[key],
  }));
};

const inquirySubmit = ({
  sessionContext,
  fields,
  siteContext,
}: InquirySubmissionType) => {
  const { name, display, type, ...rest } = fields;

  const inquirySubmissionContract: inquirySubmission = {
    page_session_id: sessionContext.pageSessionId,
    session_id: sessionContext.sessionId,
    form: {
      display: display,
      form_field_name: '', // NA
      name: name,
      page_session_id: sessionContext.pageSessionId ?? '',
      session_id: sessionContext.sessionId ?? '',
      term: '', // NA
      type: type,
      filters: {} ?? '', // NA
      values: getFormValues({ source: siteContext.site?.path ?? '', ...rest }),
    },
  };
  if (CookieStorage.enabled) {
    const cookieName = getCookieNameFromEvent(segmentEvents.INQUIRY_SUBMITTED);
    // Expire the cookie in 24 hours
    CookieStorage.set(
      cookieName,
      true,
      new Date().getTime() + ONE_DAY_IN_MILLISECONDS
    );
  }
  window.tracking.track(
    segmentEvents.INQUIRY_SUBMITTED,
    inquirySubmissionContract
  );
};

const useInquirySubmission = () => {
  const sessionContext = useSessionContext();
  const siteContext = useContext(SiteContext);
  return (fields: InquirySubmissionEvent) => {
    return inquirySubmit({ sessionContext, fields, siteContext });
  };
};

export default useInquirySubmission;
