import { useState, useEffect } from 'react';

const useAnonymousId = () => {
  const [anonId, setAnonId] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (window) {
        if (window.tracking) {
          window.tracking.ready(async function () {
            setAnonId(window.tracking.user().anonymousId());
          });
        }
      }
    };

    fetchData();
  }, []);

  return anonId;
};

export default useAnonymousId;
