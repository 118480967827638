import { ChangeEvent, useCallback, useMemo } from 'react';
import { Props as InputMaskProps } from 'react-input-mask';

interface ReactInputMaskInput {
  mask: string;
  maskChar: string;
  isUSPhoneNumber?: boolean;
}

export interface ReactInputMaskOutput {
  mask: string;
  maskChar: string;
  beforeMaskedValueChange: InputMaskProps['beforeMaskedValueChange'];
  formatValueOnAutofill: (e: ChangeEvent<HTMLInputElement>) => {
    changed: boolean;
    value: string;
  };
}

interface MaskConfig {
  mask: string;
  maskChar: string;
}

interface InternalHelperProps {
  maskConfig: MaskConfig;
  internalMaskConfig: MaskConfig;
  emptyValue: string;
  partiallyFilledInputRegex: RegExp;
}

const useReactInputMask = ({
  mask,
  maskChar,
  isUSPhoneNumber,
}: ReactInputMaskInput): ReactInputMaskOutput => {
  const helperProps: InternalHelperProps = useMemo(
    () =>
      calculateHelperProps({
        mask,
        maskChar,
      }),
    [mask, maskChar]
  );

  const formatValue = useCallback(
    (value: string): string => {
      // We only accept digits and chars, so let's remove all non-digits/chars from the input
      // value that could had been inserted by the autofill
      let clearValue = value.replace(/[^a-zA-Z\d]/g, '');

      // If is a phone number and it is longer than a US local number (10 digits),
      // we remove the country code from the beginning, _but only if it's from the US_
      // (starts with 1 and has 11 digits)
      if (
        isUSPhoneNumber &&
        clearValue.startsWith('1') &&
        clearValue.length === 11
      ) {
        clearValue = clearValue.slice(1);
      }

      const formattedValue = applyMask({
        mask: helperProps.internalMaskConfig.mask,
        maskPlaceholder: helperProps.internalMaskConfig.maskChar,
        placeholder: helperProps.maskConfig.maskChar,
        unformattedString: clearValue,
      });

      return formattedValue;
    },
    [helperProps, isUSPhoneNumber]
  );

  const isInputEmpty = useCallback(
    (value: string) => value === helperProps.emptyValue,
    [helperProps.emptyValue]
  );

  const beforeMaskedValueChange = useCallback<
    NonNullable<InputMaskProps['beforeMaskedValueChange']>
  >(
    (newState, oldState, userInput) => {
      // If a paste or autofill event inserted more than 1 char, make sure the value
      // matches the input mask
      const newValue =
        isInputEmpty(oldState.value) && insertedMultipleCharsAtOnce(userInput)
          ? formatValue(userInput)
          : newState.value;

      return {
        ...newState,
        value: newValue,
      };
    },
    [formatValue, isInputEmpty]
  );

  // Bugfix: on Chrome in iOS, autofill phone numbers are removed when value is not already
  // formatted as the mask. This happens because we're using InputMask here, and this is a
  // know issue of react-input-mask. See
  // https://www.npmjs.com/package/react-input-mask#autofill for more details.
  // And because this does not happen on desktop browsers, this cannot be tested on our specs,
  // so be sure to manually test this on a Chrome in iOS when changing this event code
  const formatValueOnAutofill = useCallback<
    ReactInputMaskOutput['formatValueOnAutofill']
  >(
    (e) => {
      let value: string = e.target.value;
      let changed = false;

      // Avoid re-processing values that already match the expected mask
      if (!value.match(helperProps.partiallyFilledInputRegex)) {
        value = formatValue(value);
        changed = true;
      }
      return { changed, value };
    },
    [formatValue, helperProps.partiallyFilledInputRegex]
  );

  return {
    beforeMaskedValueChange,
    formatValueOnAutofill,
    mask,
    maskChar,
  };
};

export const maskToRegex = (mask: string, maskChar = ''): RegExp => {
  const regexCore = mask
    // "99..." => "[\\d_][\\d_]..."
    .replace(/9/g, `[\\d${maskChar}]`)
    // "aa..." => "[a-zA-Z_][a-zA-Z_]..."
    .replace(/a/g, `[a-zA-Z${maskChar}]`)
    // "**..." => "[a-zA-Z\\d_][a-zA-Z\\d_]..."
    .replace(/\*/g, `[a-zA-Z\\d${maskChar}]`)
    // "(" => "\\("
    .replace(/\(/g, '\\(')
    // ")" => "\\)"
    .replace(/\)/g, '\\)')
    // "+" => "\\+"
    .replace(/\+/g, '\\+');

  return new RegExp(`^${regexCore}$`);
};

export const calculateHelperProps = ({
  mask,
  maskChar,
}: MaskConfig): InternalHelperProps => {
  const internalMaskChar = '#';
  const internalMask = mask.replace(/[9a\*]/g, internalMaskChar);
  const emptyValue = mask.replace(/[9a\*]/g, maskChar);

  return {
    maskConfig: {
      mask,
      maskChar,
    },
    internalMaskConfig: {
      mask: internalMask,
      maskChar: internalMaskChar,
    },
    emptyValue,
    partiallyFilledInputRegex: maskToRegex(mask, maskChar),
  };
};

const insertedMultipleCharsAtOnce = (value: string) => {
  return Boolean(value && value.length > 1);
};

export const applyMask = ({
  mask,
  maskPlaceholder,
  placeholder,
  unformattedString,
}: {
  mask: string;
  maskPlaceholder: string;
  placeholder: string;
  unformattedString: string;
}) => {
  let extraChars = '';

  // Check if we need to fill the input with placeholder values
  const maskLength = mask.split(maskPlaceholder).length - 1;
  const lengthDiff = maskLength - unformattedString.length;
  if (lengthDiff > 0) {
    extraChars += placeholder.repeat(lengthDiff);
  }

  return format({
    unformattedString: unformattedString + extraChars,
    mask,
    maskPlaceholder,
  });
};

const format = ({
  mask,
  maskPlaceholder,
  unformattedString,
}: {
  mask: string;
  maskPlaceholder: string;
  unformattedString: string;
}): string => {
  let i = 0;
  const regex = new RegExp(maskPlaceholder, 'g');
  return mask.replace(regex, () => unformattedString[i++]);
};

export default useReactInputMask;
