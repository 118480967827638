import { FieldValues } from 'react-hook-form';
import CookieStorage from '@utils/cookieStorage';
import { THIRTY_MINUTES_IN_MILLISECONDS } from '~/constants';

const STORAGE_KEY = 'inquiry-form-auto-save';

export const saveInquiryForm = <T extends FieldValues>(values: T) => {
  try {
    if (CookieStorage.enabled) {
      CookieStorage.set(
        STORAGE_KEY,
        JSON.stringify(values),
        new Date().getTime() + THIRTY_MINUTES_IN_MILLISECONDS
      );
    }
  } catch (err) {
    console.error('sessionStorage is not available');
  }
};

export const getSavedValues = <T extends FieldValues>(): T => {
  let values: Record<keyof T, unknown> = {} as Record<keyof T, unknown>;
  try {
    if (CookieStorage.enabled) {
      const savedValue = CookieStorage.get(STORAGE_KEY);
      if (savedValue) {
        values = JSON.parse(savedValue) as Record<keyof T, unknown>;
      }
    }
  } catch (err) {
    console.error('sessionStorage is not available');
  }
  return values as T;
};
