import { formData } from '@components/Analytics/events/FormFieldFocused';
import { FormType } from '@components/Analytics/events/FormStepSubmission';
import React from 'react';
import { z as zod } from 'zod';
import { InquiryFormAPIPayload } from '~/types/Inquiry';
import { getClientIp } from '~/utils/ipify';
import { customErrorMap } from '~/utils/zodCustomErrorMap';
import { LocationResponse } from '@services/modular-monolith/types/location.type';

export const sendInquiryForm = async (values: InquiryFormAPIPayload) => {
  const ip = await getClientIp();

  const response = await fetch('/api/inquiry', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      ...values,
      browser: navigator.userAgent,
      ip,
    }),
  });

  return (await response.json()) as {
    success: boolean;
    message: string;
    provider?: LocationResponse;
  };
};

export const inquiryForOptions = [
  'Parent(s)',
  'Relative(s)',
  'Friend(s)',
  'Myself',
  'Spouse',
  'Patient/Client',
  'Job',
  'Other',
];

zod.setErrorMap(customErrorMap);
export const inquiryFormSchema = zod.object({
  whoAreYouLookingFor: zod
    .string()
    .min(1, { message: 'Please make a selection' }),
  fullName: zod.string().min(1, { message: 'Please enter your full name' }),
  phoneNumber: zod
    .string()
    .min(1, { message: 'Please enter a valid U.S. phone number' })
    .regex(
      /\([\d]{3}\) [\d]{3}-[\d]{4}/,
      'Invalid phone number, please enter a valid U.S. phone number'
    ),
  email: zod
    .string()
    .email('Please enter a valid email address')
    .min(1, { message: 'Please enter a valid email address' }),
  payingWithMedicaid: zod.boolean().nullable(),
  typeOfCare: zod.string(),
  anonId: zod.string(),
  formId: zod.string(),
  trackingNotes: zod.string(),
  providerId: zod.string().nullable().optional(),
  source: zod.string().nullable().optional(),
  careLocation: zod.string(),
});

export const formFieldTypes = {
  fullName: 'text',
  email: 'text',
  phoneNumber: 'text',
  payingWithMedicaid: 'option',
  whoAreYouLookingFor: 'select',
};

export const formFieldPrompts = {
  fullName: 'Full Name',
  email: 'Email',
  phoneNumber: 'Phone',
  payingWithMedicaid: ' Paying with Medicaid only',
  whoAreYouLookingFor: 'Who are you looking for?',
  response: 'response',
};

export const handleFormFocus = (
  e: React.MouseEvent<HTMLFormElement, MouseEvent>,
  formFocused: boolean,
  setFormFocused: React.Dispatch<React.SetStateAction<boolean>>,
  formFieldFocused: (formData: formData) => void,
  formTemplateId: string,
  formType: FormType
) => {
  const target = e.target as HTMLInputElement;
  if (!formFocused && target?.name !== undefined && target?.name !== '') {
    setFormFocused(true);
    formFieldFocused({
      formDetails: {
        formTemplateId: formTemplateId,
        formType: formType,
      },
      focusData: { formType: formType, focusField: target.name },
    });
  }
};

export const handleFormBlur = (
  e: React.FocusEvent<HTMLFormElement, Element>,
  formFocused: boolean,
  inquiryForm: React.RefObject<HTMLFormElement>,
  setFormFocused: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const target = e.relatedTarget as HTMLInputElement;
  if (formFocused && target?.form !== inquiryForm.current) {
    setFormFocused(false);
  }
};
