import { Box } from '@chakra-ui/layout';
import Modal from '@components/Modal';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  title?: string;
  size: string;
  allowInnerScroll?: boolean;
  onClose: () => void;
  visible: boolean;
}

const InquiryModalForm: React.FC<Props> = ({
  children,
  title = 'Get costs',
  size,
  allowInnerScroll = false,
  onClose,
  visible,
}) => {
  const isFull = size === 'full' || size === 'full-page-modal';

  return (
    <Modal
      size={isFull ? 'full' : 'md'}
      visible={visible}
      onClose={onClose}
      title={title}
      allowInnerScroll={allowInnerScroll}
    >
      <Box display="flex" width="100%" justifyContent="center">
        <Box width="xl">{children}</Box>
      </Box>
    </Modal>
  );
};

export default InquiryModalForm;
