import { ParsedUrlQuery } from 'querystring';
import isACountyOrParish from './isACountyOrParish';
import { isAState } from './isAState';

interface GetCityParams {
  city?: string;
  countyOrCityOrProvider?: string;
  careTypeOrState?: string;
  careTypeOrProvider?: string;
  stateOrCountyOrCity?: string;
  careTypeOrCountyOrCity?: string;
}

export const getCityFromURL = (
  params: ParsedUrlQuery | null
): string | null => {
  if (!params) return null;

  const {
    city = '',
    countyOrCityOrProvider = '',
    careTypeOrState = '',
    careTypeOrProvider = '',
    stateOrCountyOrCity = '',
    careTypeOrCountyOrCity = '',
  }: GetCityParams = params;

  if (city) return city;
  // bug fix for seniorhomes on provider pages

  if (isAState(careTypeOrState) && careTypeOrProvider) {
    if (!careTypeOrCountyOrCity) return null;
    return careTypeOrCountyOrCity;
  }

  if (
    isAState(careTypeOrState) &&
    !isACountyOrParish(careTypeOrCountyOrCity) &&
    careTypeOrCountyOrCity
  ) {
    if (!careTypeOrCountyOrCity) return null;
    return careTypeOrCountyOrCity;
  }

  if (
    !isAState(stateOrCountyOrCity) &&
    !isACountyOrParish(stateOrCountyOrCity)
  ) {
    if (!stateOrCountyOrCity) return null;
    return stateOrCountyOrCity;
  }

  if (countyOrCityOrProvider && !isACountyOrParish(countyOrCityOrProvider)) {
    if (!countyOrCityOrProvider) return null;
    return countyOrCityOrProvider;
  }

  return null;
};
