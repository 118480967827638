import { formFieldFocused } from './eventContracts';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import segmentEvents from '~/config/segment-events';
import { FormType } from './FormStepSubmission';

export interface formData {
  formDetails: {
    formTemplateId: string;
    formType: FormType;
  };
  focusData: formFocus;
}
interface FormFieldFocusedType {
  sessionContext: SessionContextType;
  formData: formData;
}

export interface formFocus {
  formType: FormType;
  focusField: string;
}

const formFieldFocused = ({
  sessionContext,
  formData,
}: FormFieldFocusedType) => {
  const formFieldFocusedContract: formFieldFocused = {
    form_details_json: JSON.stringify({
      form_details: [
        {
          form_template_id: formData.formDetails.formTemplateId,
          form_type: formData.formDetails.formType,
        },
      ],
    }),
    form_type: formData.focusData.formType,
    focus_field: formData.focusData.focusField,
    page_session_id: sessionContext.pageSessionId,
    session_id: sessionContext.sessionId,
  };

  window.tracking.track(
    segmentEvents.FORM_FIELD_FOCUSED,
    formFieldFocusedContract
  );
};

const useFormFieldFocused = () => {
  const sessionContext = useSessionContext();
  return (formData: formData) => {
    return formFieldFocused({ sessionContext, formData });
  };
};

export default useFormFieldFocused;
