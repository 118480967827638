import { Grid } from '@chakra-ui/layout';
import { PropsWithChildren } from 'react';

const InquiryHorizontalForm: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid
      sx={{
        '--columns': '1',
        '@media (min-width: 48em)': { '--columns': '2' },
      }}
      gap={3}
      templateColumns="repeat(var(--columns), 1fr)"
    >
      {children}
    </Grid>
  );
};

export default InquiryHorizontalForm;
