import { formStepSubmission, formStep } from './eventContracts';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import segmentEvents from '~/config/segment-events';

interface FormStepSubmissionType {
  sessionContext: SessionContextType;
  formStepData: formStep;
}

export enum FormType {
  INQUIRY = 'inquiry',
  REVIEW = 'review',
  REVIEWS_SEARCH = 'reviews_search',
}

const formStepSubmit = ({
  sessionContext,
  formStepData,
}: FormStepSubmissionType) => {
  const formStepSubmissionContract: formStepSubmission = {
    form_step_submission_json: JSON.stringify({
      form_step_submission: [formStepData],
    }),
    page_session_id: sessionContext.pageSessionId,
    session_id: sessionContext.sessionId,
    form_type: formStepData.form_type,
  };

  window.tracking.track(
    segmentEvents.FORM_STEP_SUBMITTED,
    formStepSubmissionContract
  );
};

const useFormStepSubmission = () => {
  const sessionContext = useSessionContext();
  return (formStepData: formStep) => {
    return formStepSubmit({ sessionContext, formStepData });
  };
};

export default useFormStepSubmission;
