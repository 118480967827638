import { Catalog } from '~/types/LocaleCatalog';
import { toCapitalizedWords } from '@utils/strings';
import { careTypeRollups } from './careTypeRollups';
import { DEFAULT_CARE_TYPE } from '~/config/senior-homes';
import { careTypeToApiCareType } from './careTypeToApiCareType';
import { APIDOMAINS } from '~/types/Domains';

export const generateLinkText = (type: string, data: Catalog) => {
  switch (type) {
    case 'state':
      return toCapitalizedWords(data.state || '');
    case 'city':
      return toCapitalizedWords(data.city || '');
    case 'countyCities':
      const careType = toCapitalizedWords(String(data.careType || ''));
      const city = toCapitalizedWords(data.city || '');
      if (city === '' || careType === '') return '';
      return `${careType} in ${city}`;
    default:
      return '';
  }
};

// TODO generate links by domain
/**
 * Generates cities or counties list based on parameters
 *
 * @param careType - The type of care.
 * @param stateSlug - The state slug.
 * @param slug - The geo slug (optional).
 * @returns The generated link.
 */
export const generateGeosLinks = (
  careType: string,
  stateSlug: string,
  slug?: string
) => {
  const rollUpType = careTypeRollups[careType || DEFAULT_CARE_TYPE];
  const path = `/${rollUpType}${
    careType === rollUpType ? '/' : `/${careType}/`
  }${stateSlug}`;

  return `${path}/${slug}`;
};

export const getDomainFilter = (domain: string): string => {
  return APIDOMAINS[domain];
};

// Parse care type to match api care type in the table service_categories
export const parseApiCareType = (careType: string): string => {
  return careTypeToApiCareType[careType || DEFAULT_CARE_TYPE];
};
