import { formatInTimeZone } from 'date-fns-tz';
import { DateTime } from 'luxon';

const DEFAULT_TIMEZONE = 'UTC';

/**
 * Formats a date, ensuring it is the same in the server and client..
 * @param date - The date to format.
 * @param format - The format string to use for formatting.
 * @param timeZone - The time zone to use for formatting.
 * @returns The formatted date string, or null if an error occurred.
 */
export const safeFormat = (
  date: string | number | Date,
  format: string,
  timeZone: string = DEFAULT_TIMEZONE
) => {
  try {
    return formatInTimeZone(date, timeZone, format);
  } catch (error) {
    return null;
  }
};

export const getUserDetailedTimeZone = () => {
  const now = new Date();

  // Specify the timeZoneName option as a string value
  const options = { timeZoneName: 'long' as const };

  const parts = new Intl.DateTimeFormat(undefined, options).formatToParts(now);

  // Find the part with the timezone name
  const timeZonePart = parts.find((part) => part.type === 'timeZoneName');

  return timeZonePart ? timeZonePart.value : null;
};

export const relativeTimeToCallcenterTime = (
  timeString: string,
  userTimezone: string
): string => {
  // Get the current date in the Los Angeles timezone.
  const currentDate = DateTime.now().setZone('America/Los_Angeles');

  // Parse the time string.
  const [hours, minutes] = timeString.split(':').map(Number);

  // Set the specific time in Los Angeles.
  const dateTimeInLosAngeles = currentDate.set({
    hour: hours,
    minute: minutes,
  });

  // Convert the date and time to the user's timezone.
  const dateTimeInUserTimezone = dateTimeInLosAngeles.setZone(userTimezone);

  // Format the date and time in the user's timezone.
  const formattedTime = dateTimeInUserTimezone.toLocaleString({
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return formattedTime;
};
