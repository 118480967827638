import { isSunday, isSaturday, isWithinInterval } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const isCallCenterOpen = (
  timeFromWkdy: string,
  timeToWkdy: string,
  timeFromWknd: string,
  timeToWknd: string
) => {
  const [hoursFromWkdy, minutesFromWkdy] = timeFromWkdy.split(':').map(Number);
  const [hoursToWkdy, minutesToWkdy] = timeToWkdy.split(':').map(Number);
  const [hoursFromWknd, minutesFromWknd] = timeFromWknd.split(':').map(Number);
  const [hoursToWknd, minutesToWknd] = timeToWknd.split(':').map(Number);
  const losAngelesTimeZone = 'America/Los_Angeles';

  const currentDateTimeUTC = zonedTimeToUtc(new Date(), 'UTC');

  const currentDateTimeLosAngeles = utcToZonedTime(
    currentDateTimeUTC,
    losAngelesTimeZone
  );

  const year = currentDateTimeLosAngeles.getFullYear();
  const month = currentDateTimeLosAngeles.getMonth();
  const day = currentDateTimeLosAngeles.getDate();

  if (
    isSunday(currentDateTimeLosAngeles) ||
    isSaturday(currentDateTimeLosAngeles)
  ) {
    const closeTime = new Date(year, month, day, hoursToWknd, minutesToWknd, 0);
    const wkndOpenTime = new Date(
      year,
      month,
      day,
      hoursFromWknd,
      minutesFromWknd,
      0
    );
    return isWithinInterval(currentDateTimeLosAngeles, {
      start: wkndOpenTime,
      end: closeTime,
    });
  } else {
    const closeTime = new Date(year, month, day, hoursToWkdy, minutesToWkdy, 0);
    const wkdyOpenTime = new Date(
      year,
      month,
      day,
      hoursFromWkdy,
      minutesFromWkdy,
      0
    );
    return isWithinInterval(currentDateTimeLosAngeles, {
      start: wkdyOpenTime,
      end: closeTime,
    });
  }
};
